import { DateTime, Settings } from "luxon"

Settings.defaultLocale = "ru"

export function formatReadingTime(minutes) {
  let cups = Math.round(minutes / 5)
  let bowls = 0
  if (cups > 5) {
    return `${new Array(Math.round(cups / Math.E))
      .fill("🍱")
      .join("")} ${minutes} мин.`
  } else {
    return `${new Array(cups || 1).fill("☕️").join("")} ${minutes} мин.`
  }
}

// `lang` is optional and will default to the current user agent locale
export function formatPostDate(date, lang) {
  lang = "ru"
  const args = [
    lang,
    { day: "numeric", month: "long", year: "numeric" },
  ].filter(Boolean)

  date = new Date(date)

  if (typeof Date.prototype.toLocaleDateString !== "function") {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL)
  }

  return date.toLocaleDateString(...args)
}
