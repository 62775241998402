import React from "react"
import headerLogo from "../assets/header-logo.png"
import { rhythm } from "../utils/typography"

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          // display: "flex",
          marginBottom: rhythm(2),
        }}
      >
        <img
          src={headerLogo}
          alt={`Howtojs`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            // width: rhythm(2),
            // height: rhythm(2),
            borderRadius: "50%",
          }}
        />
        {/* <p style={{ maxWidth: 310 }}>Ответы, которые ты всегда искал.</p> */}
      </div>
    )
  }
}

export default Bio
