// Please keep keys lowercase!
exports.supportedLanguages = {
  en: "English",
  // ru: "Русский",
  // tr: "Türkçe",
  // es: "Español",
  // ko: "한국어",
  // sv: "Svenska",
  // it: "Italiano",
  // id: "Bahasa Indonesia",
  // "pt-br": "Português do Brasil",
  // pl: "Polski",
  // "zh-hant": "繁體中文",
  // "zh-hans": "简体中文",
  // ja: "日本語",
  // fr: "Français",
  // he: "עברית",
  // hu: "Magyar",
  // vi: "Tiếng Việt",
  // th: "ไทย",
  // my: "မြန်မာဘာသာ",
  // sk: "Slovenčina",
  // te: "తెలుగు",
  // uk: "Українська",
  // bg: "Български",
  // cs: "Čeština",
  // de: "Deutsch",
  // nl: "Nederlands",
  // nb: "Norsk",
  // fa: "فارسی",
  // ar: "العربية",
  // sr: "srpski",
};
